import React from "react";

const ClustersIcon = () => (
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.8739L2 7.8739L12 12.8739L22 7.8739L12 2.8739Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 17.8739L12 22.8739L22 17.8739"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12.8739L12 17.8739L22 12.8739"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClustersIcon;
