import React from "react";

const SourcesIcon = () => (
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_151_365)">
      <path
        d="M18.4617 10H17.2017C16.8275 8.551 16.0545 7.23599 14.9703 6.2044C13.8861 5.1728 12.5344 4.46599 11.0686 4.16428C9.60279 3.86256 8.08175 3.97804 6.67831 4.49759C5.27487 5.01714 4.0453 5.91993 3.12931 7.10338C2.21332 8.28683 1.64765 9.70348 1.49657 11.1924C1.34548 12.6812 1.61505 14.1826 2.27463 15.526C2.93422 16.8693 3.95737 18.0007 5.22784 18.7916C6.4983 19.5824 7.96514 20.0011 9.46166 20H18.4617C19.7877 20 21.0595 19.4732 21.9972 18.5355C22.9349 17.5979 23.4617 16.3261 23.4617 15C23.4617 13.6739 22.9349 12.4021 21.9972 11.4645C21.0595 10.5268 19.7877 10 18.4617 10Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_151_365">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.46167)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SourcesIcon;
