/** Constants for recoil keys, to avoid unintentional duplication. */

/*eslint sort-keys: "error"*/
export default {
  CURRENT_ENVIRONMENT: "CURRENT_ENVIRONMENT",
  CURRENT_ENVIRONMENT_ID: "CURRENT_ENVIRONMENT_ID",
  ENVIRONMENTS: "ENVIRONMENTS",
  ENVIRONMENTS_WITH_HEALTH: "ENVIRONMENTS_WITH_HEALTH",
  LOADED_ENVIRONMENTS: "LOADED_ENVIRONMENTS",
  MAYBE_ENVIRONMENTS_FOR_REGION: "MAYBE_ENVIRONMENTS_FOR_REGION",
};
/*eslint-disable sort-keys*/
