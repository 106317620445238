import React from "react";

const LockIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.5"
      y="9"
      width="14.9996"
      height="11.9996"
      rx="2.99991"
      stroke="#472F85"
      strokeWidth="1.49996"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.49609 8.99982V5.99991C7.49609 4.34311 8.8392 3 10.496 3H13.4959C15.1527 3 16.4958 4.34311 16.4958 5.99991V8.99982"
      stroke="#472F85"
      strokeWidth="1.49996"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockIcon;
