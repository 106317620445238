/**
 * @module
 * Nav bar for base layout, because the file was getting too big.
 */

import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  chakra,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Tag,
  Text,
  useColorMode,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useRecoilValue_TRANSITION_SUPPORT_UNSTABLE } from "recoil";

import FreeTrialNotice from "~/components/FreeTrialNotice";
import { SUPPORT_HREF } from "~/components/SupportLink";
import SwitchStackModal from "~/components/SwitchStackModal";
import blackLogo from "~/img/logo-black.svg";
import whiteLogo from "~/img/logo-white.svg";
import EnvironmentSelectField from "~/layouts/EnvironmentSelect";
import ProfileDropdown, {
  AVATAR_WIDTH,
  ProfileMenuItems,
} from "~/layouts/ProfileDropdown";
import {
  currentEnvironmentState,
  LoadedEnvironment,
} from "~/recoil/environments";
import { useRegionSlug } from "~/region";
import { MaterializeTheme } from "~/theme";

export const NAV_HORIZONTAL_SPACING = 4;
export const NAV_HOVER_STYLES = {
  bg: "semanticColors.background.tertiary",
};
export const NAV_LOGO_HEIGHT = "80px";

function isEnvironmentHealthy(environment?: LoadedEnvironment) {
  return environment?.state === "enabled" && environment.health === "healthy";
}

export const HideIfEnvironmentUnhealthy = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const currentEnvironment = useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
    currentEnvironmentState
  );

  if (!isEnvironmentHealthy(currentEnvironment)) {
    return null;
  }

  return <>{children}</>;
};

const NavBar = () => {
  const { colors } = useTheme<MaterializeTheme>();
  const { colorMode } = useColorMode();

  return (
    <Flex
      direction={{ base: "row", lg: "column" }}
      justify="flex-start"
      align={{ base: "center", lg: "stretch" }}
      pb={{ base: 0, lg: 2 }}
      bg={colors.semanticColors.background.secondary}
      color={colors.semanticColors.foreground.primary}
      minH={{ base: "auto", lg: "full" }}
      minW="240px"
      borderRightWidth={{ base: 0, lg: 1 }}
      borderBottomWidth={{ base: 1, lg: 0 }}
      borderColor={colors.semanticColors.border.primary}
    >
      <HStack
        mx={0}
        px={NAV_HORIZONTAL_SPACING}
        flex={0}
        width="full"
        justifyContent="flex-start"
        minHeight={{ base: "auto", lg: NAV_LOGO_HEIGHT }}
        order={1}
      >
        <NavMenuCompact display={{ base: "block", lg: "none" }} />
        <HStack
          as={RouterLink}
          to="/"
          mx={0}
          flex={0}
          width="full"
          justifyContent="flex-start"
          minHeight={{ base: "auto", lg: NAV_LOGO_HEIGHT }}
          order={1}
        >
          <VStack
            position="relative"
            flex="0 0 24px"
            mr={{ base: 1, md: 2, lg: 0 }}
            ml={0}
          >
            <chakra.img
              src={colorMode === "light" ? blackLogo : whiteLogo}
              height={{ base: 6, md: 9 }}
              aria-label="Logo"
            ></chakra.img>
          </VStack>
          <Text fontWeight="700" fontSize="md" pt="3px">
            Materialize
          </Text>
        </HStack>
      </HStack>
      <Flex
        flex={0}
        alignItems="flex-start"
        justifyContent="stretch"
        px={NAV_HORIZONTAL_SPACING}
        order={{ base: 100, lg: 2 }}
      >
        <React.Suspense
          fallback={
            <Box minH={{ base: "auto", lg: "54px" }}>
              <Spinner />
            </Box>
          }
        >
          <EnvironmentSelectField />
        </React.Suspense>
      </Flex>
      <NavMenu
        order={{ base: 2, lg: 3 }}
        display={{ base: "none", lg: "flex" }}
      />
      <Spacer order={{ base: 3, lg: 4 }} />
      <Flex
        order={4}
        direction={{ base: "row", lg: "column" }}
        align={{ base: "center", lg: "stretch" }}
        fontSize="sm"
      >
        <FreeTrialNotice my="6" mx="4" />
        <SwitchStackModal />
        <HelpDropdown />
        <ProfileDropdown width="100%" display={{ base: "none", lg: "flex" }} />
      </Flex>
    </Flex>
  );
};

type NavItemType = {
  label: string;
  href: string;
  isInternal?: boolean;
  hideIfEnvironmentUnhealthy?: boolean;
};

const getNavItems = (
  regionSlug: string,
  flags: ReturnType<typeof useFlags>
): NavItemType[] => {
  return [
    {
      label: "Connect",
      href: `/regions/${regionSlug}/connect`,
    },
    {
      label: "Clusters",
      href: `/regions/${regionSlug}/clusters`,
      hideIfEnvironmentUnhealthy: true,
    },
    {
      label: "Sources",
      href: `/regions/${regionSlug}/sources`,
      hideIfEnvironmentUnhealthy: true,
    },
    {
      label: "Sinks",
      href: `/regions/${regionSlug}/sinks`,
      hideIfEnvironmentUnhealthy: true,
    },
    ...(flags["source-creation-41"]
      ? [
          {
            label: "Secrets",
            href: `/regions/${regionSlug}/secrets`,
            hideIfEnvironmentUnhealthy: true,
          },
          {
            label: "Connections",
            href: `/regions/${regionSlug}/connections`,
            hideIfEnvironmentUnhealthy: true,
          },
        ]
      : []),
    // { label: "Editor", href: "/editor" },
    {
      label: "Docs",
      href: "//materialize.com/docs/get-started/",
    },
  ];
};

const NavMenu = (props: BoxProps) => {
  const flags = useFlags();
  const regionSlug = useRegionSlug();

  const navItems = getNavItems(regionSlug, flags);

  return (
    <VStack
      data-test-id="nav-lg"
      spacing="0"
      flex="2"
      alignSelf="stretch"
      alignItems="stretch"
      mt={0}
      mb={{ base: 0.5, xl: 1 }}
      {...props}
    >
      {navItems.map(({ label, href, hideIfEnvironmentUnhealthy }) =>
        hideIfEnvironmentUnhealthy ? (
          <HideIfEnvironmentUnhealthy key={label}>
            <NavItem label={label} href={href} />
          </HideIfEnvironmentUnhealthy>
        ) : (
          <NavItem key={label} label={label} href={href} />
        )
      )}
    </VStack>
  );
};

type NavMenuCompactProps = MenuButtonProps;

const NavMenuCompact = (props: NavMenuCompactProps) => {
  const flags = useFlags();
  const regionSlug = useRegionSlug();

  const navItems = getNavItems(regionSlug, flags);

  return (
    <Menu data-test-id="nav-sm">
      <MenuButton
        as={IconButton}
        aria-label="Menu"
        title="Menu"
        icon={<HamburgerIcon />}
        display={{ base: "block", xl: "none" }}
        variant="outline"
        mr={2}
        {...props}
      />
      <MenuList>
        {navItems.map(
          ({ label, href, isInternal, hideIfEnvironmentUnhealthy }) =>
            hideIfEnvironmentUnhealthy ? (
              <HideIfEnvironmentUnhealthy key={label}>
                <MenuItem as={RouterLink} to={href}>
                  {`${label}${isInternal ? " (internal)" : ""}`}
                </MenuItem>
              </HideIfEnvironmentUnhealthy>
            ) : (
              <MenuItem as={RouterLink} key={label} to={href}>
                {`${label}${isInternal ? " (internal)" : ""}`}
              </MenuItem>
            )
        )}
        <ProfileMenuItems />
      </MenuList>
    </Menu>
  );
};

export function isSubroute(route: string, potentialSubroute: string) {
  const routeSegments = route.split("/").filter(Boolean);
  const potentialSubrouteSegments = potentialSubroute
    .split("/")
    .filter(Boolean);

  return routeSegments.every(
    (segment, index) => segment === potentialSubrouteSegments[index]
  );
}

const NavItem = (props: NavItemType) => {
  const { colors } = useTheme<MaterializeTheme>();
  const location = useLocation();
  const href = props.href || "#";
  const active = isSubroute(href, location.pathname);

  const linkContents = (
    <HStack
      aria-current={active ? "page" : undefined}
      spacing="2"
      width="full"
      px={NAV_HORIZONTAL_SPACING}
      py={2}
      transition="all 0.2s"
      color={colors.semanticColors.foreground.primary}
      _hover={NAV_HOVER_STYLES}
      _activeLink={{
        bg: colors.semanticColors.background.tertiary,
        color: colors.semanticColors.foreground.primary,
        paddingLeft: "4px",
        borderRightWidth: { base: "0px", lg: "2px" },
        borderRightColor: {
          base: "transparent",
          lg: colors.semanticColors.accent.purple,
        },
        px: 4,
      }}
    >
      <Box fontWeight="semibold">{props.label}</Box>
      {props.isInternal && (
        <Tag size="sm" colorScheme="purple">
          internal
        </Tag>
      )}
    </HStack>
  );

  /* react-router-dom doesn't support external links, amazingly */
  if (href.search("//") === -1) {
    return (
      <HStack as={RouterLink} to={href}>
        {linkContents}
      </HStack>
    );
  }
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {linkContents}
    </a>
  );
};

const HelpDropdown = () => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <Menu>
      <MenuButton
        aria-label="Help"
        title="Help"
        _hover={NAV_HOVER_STYLES}
        px={NAV_HORIZONTAL_SPACING}
        py={2}
      >
        <HStack>
          {/* The wrapper box keeps the centers of the circles aligned */}
          <Flex
            h={AVATAR_WIDTH}
            w={AVATAR_WIDTH}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              bg={colors.semanticColors.background.tertiary}
              rounded="full"
              h="8"
              w="8"
              color={colors.semanticColors.foreground.primary}
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="md"
            >
              ?
            </Box>
          </Flex>
          <Text color={colors.semanticColors.foreground.primary}>Help</Text>
        </HStack>
      </MenuButton>
      <MenuList>
        <HelpDropdownLink href="https://materialize.com/docs/">
          Documentation
        </HelpDropdownLink>
        <HelpDropdownLink href="https://materialize.com/s/chat">
          Join us on Slack
        </HelpDropdownLink>
        <HelpDropdownLink href={SUPPORT_HREF}>Help Center</HelpDropdownLink>
      </MenuList>
    </Menu>
  );
};

interface HelpDropdownLinkProps {
  href: string;
  children: React.ReactNode;
}

const HelpDropdownLink = (props: HelpDropdownLinkProps) => {
  return (
    <MenuItem as="a" href={props.href} target="_blank" fontWeight="medium">
      {props.children}
    </MenuItem>
  );
};

export default NavBar;
